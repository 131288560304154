import React, { useState } from 'react';
import { connect }                    from 'react-redux'
import { withStyles }                 from '@material-ui/core/styles';
import validator from 'validator'
import phone from 'phone'
import FormValidator from '../../../modules/FormValidator'
import {
    updateField,
    submit,
    reset
}                                     from '../../../redux/actions/userForm'
import {
    setSnackbar
} from '../../../redux/actions/snackbar'
import { 
    Paper,
    Typography,
    Grid
} from '@material-ui/core'
import Page from '../../../components/Page'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import checkError from '../../../modules/check-error'


import styles from './styles';

const Enroll = (props) => {
    const { classes, updateField, userForm, submit, reset, setSnackbar } = props;

    const [submitting, setSubmitting] = useState(false);

    const refs = {
        given_name: React.createRef(),
        family_name: React.createRef(),
        email: React.createRef(),
        phone: React.createRef()
    }

    async function handleSubmit() {
        const invalidFields = FormValidator(refs, updateField);
        if(invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message);
        }
        
        setSubmitting(true);
        try {
            await submit()
            setSnackbar("User was enrolled")
            reset();
        }
        catch(error) {
            setSnackbar(checkError(error));
        }
        setSubmitting(false);
    }

    return (
        <Page className={classes.root}>
            <div className={classes.title}>
                <Typography variant={"h5"}>
                    Enroll Doctor
                </Typography>
            </div>
            <Paper className={classes.attributes}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Input 
                            ref={el => refs.given_name = el}
                            marginBottom={8}
                            value={userForm.given_name.value}
                            onChange={e => updateField("given_name", e.target.value)}
                            label="First Name"
                            error={!userForm.given_name.valid}
                            errorMessage="First name is required"
                            validator={() => userForm.given_name.value.length > 0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input 
                            ref={el => refs.family_name = el}
                            marginBottom={8}
                            value={userForm.family_name.value}
                            onChange={e => updateField("family_name", e.target.value)}
                            label="Last Name"
                            error={!userForm.family_name.valid}
                            errorMessage="Last name is required"
                            validator={() => userForm.family_name.value.length > 0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Input 
                            ref={el => refs.email = el}
                            marginBottom={20}
                            value={userForm.email.value}
                            onChange={e => updateField("email", e.target.value)}
                            label="Email"
                            error={!userForm.email.valid}
                            errorMessage="A valid email is required"
                            validator={() => validator.isEmail( userForm.email.value )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input 
                            ref={el => refs.phone = el}
                            marginBottom={20}
                            value={userForm.phone.value}
                            onChange={e => updateField("phone", e.target.value)}
                            label="Phone"
                            error={!userForm.phone.valid}
                            errorMessage="A valid phone # is required"
                            validator={() => phone(userForm.phone.value).length > 0}
                        />
                    </Grid>
                </Grid>

                <Button
                    loading={submitting}
                    className={classes.submitButton}
                    text={"Enroll!"}
                    color='primary'
                    size='medium'
                    onClick={handleSubmit}
                />
            </Paper>
        </Page>
    );
}

const mapStateToProps = ({ userForm }) => ({ userForm })
const actionCreators = { updateField, submit, reset, setSnackbar };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Enroll));