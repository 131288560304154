const styles = theme => ({
    root: {
        // backgroundColor: 
        // height: theme.buttonHeight,
    },
    // buttonRoot: {
    //     margin: theme.spacing.unit
    // },
    // contained: {
    //     boxShadow: 'none',
    //     fontSize: 16,
    //     fontWeight: 600,
    //     textTransform: 'unset'
    // }
});

export default styles;