
const styles = theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
});

export default styles;