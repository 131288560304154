const styles = (theme) => ({
    paper: {
        padding: 30
    },
    title: {
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    attributes: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column'
    },
    attribute: {
        marginBottom: 30,
        padding: 30,
        borderRadius: 10,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    submitButton: {
        alignSelf: 'flex-end'
    }
})

export default styles;