import { InputRed } from '../../styles';

const styles = theme => ({
    label: {
        color: InputRed
    },
    visibility: {
        cursor: 'pointer'
    }
});

export default styles;