import cloneDeep from 'lodash.clonedeep'
import AWSConfig from '../../aws/AWSConfig'
import { 
  GET_USER_DETAIL_ERROR, 
  GET_USER_DETAIL_SUCCESS, 
  RESET_USER_DETAIL,
  TOGGLE_USER_ENABLED_ERROR,
  TOGGLE_USER_ENABLED_SUCCESS,
  USER_UPDATE_ATTRIBUTE_ERROR,
  USER_UPDATE_ATTRIBUTE_SUCCESS,
  USER_UPDATE_ATTRIBUTES_ERROR,
  USER_UPDATE_ATTRIBUTES_SUCCESS,
  USER_DETAIL_UPDATE_FIELD
} from '../reducers/userDetail';

export const get = (username) => async (dispatch) => {
  try {

    let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

    let user = await CognitoIdentityServiceProvider.adminGetUser({
      UserPoolId: AWSConfig.UserPool,
      Username: username
    }).promise()

    let groups = await CognitoIdentityServiceProvider.adminListGroupsForUser({
      UserPoolId: AWSConfig.UserPool,
      Username: username,
      Limit: 50,
      NextToken: null
    }).promise()

    user.UserAttributes.forEach(attribute => {
      user[attribute.Name] = attribute.Value
    })

    user.groups = [];
    groups.Groups.forEach(group => {
      user.groups.push(group.GroupName);
    })
    
    dispatch({
      type: GET_USER_DETAIL_SUCCESS,
      payload: user
    })

    return user
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: GET_USER_DETAIL_ERROR
    })

    throw error
  }
}

export const toggleEnabled = () => async (dispatch, getState) => {
  const { userDetail } = cloneDeep(getState());

  try {
    let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

    if(userDetail.Enabled) {
      await CognitoIdentityServiceProvider.adminDisableUser({
        UserPoolId: AWSConfig.UserPool,
        Username: userDetail.Username
      }).promise()
    }
    else {
      await CognitoIdentityServiceProvider.adminEnableUser({
        UserPoolId: AWSConfig.UserPool,
        Username: userDetail.Username
      }).promise()
    }

    userDetail.Enabled = !userDetail.Enabled

    dispatch({
      type: TOGGLE_USER_ENABLED_SUCCESS,
      payload: userDetail
    })

    return userDetail
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: TOGGLE_USER_ENABLED_ERROR
    })

    throw error
  }
}

export const updateAttribute = (Name, Value) => async (dispatch, getState) => {
  const { userDetail } = cloneDeep(getState());
  try {
    let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

    await CognitoIdentityServiceProvider.adminUpdateUserAttributes({
      UserAttributes: [{Name, Value}],
      UserPoolId: AWSConfig.UserPool,
      Username: userDetail.Username
    }).promise()

    userDetail[Name] = Value

    dispatch({
      type: USER_UPDATE_ATTRIBUTE_SUCCESS,
      payload: userDetail
    })

    return userDetail
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: USER_UPDATE_ATTRIBUTE_ERROR
    })

    throw error
  }
}

export const updateAttributes = () => async (dispatch, getState) => {
  const { userDetail } = cloneDeep(getState());
  try {
    let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

    let attributes = [];
    userDetail.UserAttributes.forEach(attribute => {
      if(userDetail[attribute.Name] !== attribute.Value) {
        attributes.push({ Name: attribute.Name, Value: userDetail[attribute.Name] })
      }
    })
    
    // No attributes were actually updated
    if( attributes.length === 0 ) {
      dispatch({
        type: USER_UPDATE_ATTRIBUTES_SUCCESS
      })

      return userDetail;
    }

    await CognitoIdentityServiceProvider.adminUpdateUserAttributes({
      UserAttributes: attributes,
      UserPoolId: AWSConfig.UserPool,
      Username: userDetail.Username
    }).promise()

    dispatch(get(userDetail.Username))

    dispatch({
      type: USER_UPDATE_ATTRIBUTES_SUCCESS,
    })

    return userDetail
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: USER_UPDATE_ATTRIBUTES_ERROR
    })

    throw error
  }
}

export const updateField = (Field, Value) => (dispatch, getState) => {
  const { userDetail } = cloneDeep(getState());

  userDetail[Field] = Value;

  dispatch({
    type: USER_DETAIL_UPDATE_FIELD,
    payload: userDetail
  })

  return userDetail;

}

export const reset = () => {
  return {
    type: RESET_USER_DETAIL
  };
}