import React from 'react';
import {
  Route,
  Router,
} from 'react-router-dom';

import Authorizer from './Authorizer';
import { history } from './redux/config';

import Snackbar from './components/Snackbar';

const _Router = props => {
  return (
    <React.Fragment>
      
      <Snackbar />

      <Router history={history}>
          <Route path="/" component={Authorizer} /> 
      </Router>
    </React.Fragment>
  );
}



export default _Router;
