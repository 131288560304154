import { combineReducers } from 'redux';

// Import reducers here
import cognitoUser          from './cognitoUser';
import snackbar             from './snackbar';
import user                 from './user';
import modal                from './modal';
import userDetail           from './userDetail';
import userForm             from './userForm';
import users                from './users';

const rootReducer = combineReducers({
  cognitoUser,
  modal,
  snackbar,
  user,
  userForm,
  users,
  userDetail
});

export default rootReducer;
