import React, { useState, useEffect } from 'react';
import { connect }                    from 'react-redux'
import { get as getUsers }            from '../../../redux/actions/users'
import { withStyles }                 from '@material-ui/core/styles';
import { 
  Grid, 
  TextField,
  MenuItem,
  OutlinedInput,
  Select,
  FormControl,
  InputLabel
}                   from '@material-ui/core';
import Page         from '../../../components/Page';
import CustomTable  from '../../../components/CustomTable'
import ActionCell   from '../../../components/CustomTable/ActionCell'
import Button       from '../../../components/Button'

import styles from './styles';

let TableHeaders = ["Email", "Username", "Enabled", ""]

const AdminDashboard = (props) => {
  const { classes, getUsers, users } = props;

  const [usersLoading, setUsersLoading] = useState(false)
  const [searchBy, setSearchBy] = useState('email')
  const [search, setSearch] = useState('')

  useEffect(() => {
    async function _getUsers(query = {}) {
      if(search && searchBy) {
        query.Filter = `${searchBy} ^= "${search}"`
      }
  
      setUsersLoading(true);
      try {
        await getUsers(query)
        setUsersLoading(false);
      }
      catch(error){
        setUsersLoading(false);
      }
    }

    _getUsers();
  }, [searchBy, search, getUsers]);


  async function handleLoadMore() {
    try {
      let query = {PaginationToken: users.PaginationToken, users: users.rows};
      if(search && searchBy) {
        query.Filter = `${searchBy} ^= "${search}"`
      }
      await getUsers(query);
    }
    catch(error) {
      console.log(error);
    }
  }

  function translateSearchFieldLabel() {
    return {
      email: "Email",
      username: "Username",
      phone_number: "Phone #",
      given_name: "First Name",
      family_name: "Last Name",
      status: "true or false"
    }[searchBy]
  }

  return (
    <Page className={classes.root}>
        <Grid className={classes.search} container alignItems={'center'} spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-simple">
                Search By
              </InputLabel>
              <Select
                fullWidth
                value={searchBy}
                onChange={e => setSearchBy(e.target.value)}
                input={<OutlinedInput name="Search By" labelWidth={90}/>}>
                <MenuItem value={"email"}>Email</MenuItem>
                <MenuItem value={"username"}>Username</MenuItem>
                <MenuItem value={"phone_number"}>Phone #</MenuItem>
                <MenuItem value={"given_name"}>First Name</MenuItem>
                <MenuItem value={"family_name"}>Last Name</MenuItem>
                <MenuItem value={"status"}>Status (true / false)</MenuItem>
              </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <TextField
              fullWidth
              label={translateSearchFieldLabel()}
              value={search}
              onChange={e => setSearch(e.target.value)}
              variant="outlined" />
          </Grid>
        </Grid>
        {
          <CustomTable
              paginated
              PaginationToken={users.PaginationToken}
              startOrderIndex={0}
              loading={usersLoading}
              headers={TableHeaders}
              onLoadMore={handleLoadMore}
              rows={
                  Array.isArray(users.rows) ? users.rows.map((row) => ({
                      cols: [
                          row.email,
                          row.Username,
                          row.Enabled ? "Yes" : "No",
                          <ActionCell>
                            <Button
                              text={"Details"}
                              color='primary'
                              size='medium'
                              onClick={e => props.history.replace(`/dashboard/user/${row.Username}`)}
                            />
                          </ActionCell>
                      ]
                  })) : []
              } />
      }
    </Page>
  );
}

const mapStateToProps = ({ users }) => ({ users})
const actionCreators = { getUsers };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(AdminDashboard));