import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'

class AWSConfig {
    static configure(options) {
        AWSConfig.UserPool = options.UserPool || ""
        AWSConfig.Region = options.Region || "us-west-2"
    }

    static async GetCognitoIdentityServiceProvider() {
        let creds = await Auth.currentCredentials()
        var CognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
          credentials: Auth.essentialCredentials(creds),
          region: AWSConfig.Region
        });

        return CognitoIdentityServiceProvider;
    }
}

export default AWSConfig