import React       from 'react';
import { connect } from 'react-redux';
import CloseIcon   from '@material-ui/icons/Close';
import IconButton  from '@material-ui/core/IconButton';
import Snackbar    from '@material-ui/core/Snackbar';

import { setSnackbar } from '../../redux/actions/snackbar';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

const StyledSnackbar = (props) => {
  const { message } = props.snackbar;

  function handleClose (event, reason) {
     // do not close on click away
     // why? when this is here, it comes back after clicking it away, and reloading the page
     // because of the redux persist store
    // if (reason === 'clickaway') return;
    props.setSnackbar('');
  };

  let open = true;

  if (message === '') open = false;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={AUTO_HIDE_TIME}
      onClose={handleClose}
      message={
        <span>
          { message }
        </span>
      }
      action={
        <IconButton
          color='inherit'
          aria-label='close snackbar'
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
}

const mapStateToProps = ({ snackbar }) => ({ snackbar });

export default connect(mapStateToProps, { setSnackbar })(StyledSnackbar);