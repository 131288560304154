const checkError = error => {
    if (typeof error === 'string') {
        console.error(new Error(error));
        return error;
    } else {
        console.error(error);
        if(error.response){
            return error.response.data.message;
        } 
        else {
            return error.message
        }
    }
};

export default checkError;