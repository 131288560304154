import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { 
    get as getUserDetail,
    toggleEnabled,
    updateAttribute,
    updateAttributes,
    updateField
} from '../../../redux/actions/userDetail'
import {
    setModal
} from '../../../redux/actions/modal'
import {
    setSnackbar
} from '../../../redux/actions/snackbar'
import { withStyles } from '@material-ui/core/styles';

import { 
    Paper,
    Typography,
    Grid,
    MenuItem
} from '@material-ui/core'
import Page from '../../../components/Page'
import styles from './styles'
import Input from '../../../components/Input'
import Select from '../../../components/Input/Select'
import Button from '../../../components/Button'
import checkError from '../../../modules/check-error'

const UserDetails = (props) =>  {
    const { classes, getUserDetail, history, match, userDetail, toggleEnabled, updateField, updateAttributes, setSnackbar } = props
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function _getUserDetail() {
            setLoading(true);
            
            try {
                await getUserDetail(match.params.id)
                setLoading(false);
            }
            catch(error) {
                setLoading(false);
                setSnackbar("User was not found");
                history.push('/dashboard');
                // Navigation back
            }
        }

        _getUserDetail()
    }, [getUserDetail, history, match, setSnackbar])

    async function handleSubmit() {
        setLoading(true);
        try {
            await updateAttributes()
            setLoading(false);
            setSnackbar("Attributes updated");
        }
        catch(error) {
            setLoading(false)
            setSnackbar(checkError(error));
        }
    }

    async function handleToggleEnabled() {
        try {
            await toggleEnabled()
        }
        catch(error) {
            console.log(error);
        }
    }

    return (
        <Page className={classes.root}>
            <div className={classes.title}>
                <Typography variant={"h5"}>
                    User Details
                </Typography>
                <div className={classes.buttons}>
                    <Button 
                        text={userDetail.Enabled ? "Disable User" : "Enable User"} 
                        disabled={loading} 
                        onClick={handleToggleEnabled} 
                        size="small" 
                        color="primary"/>
                </div>
            </div>
            <Paper className={classes.attributes}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                        <Input 
                            value={userDetail.given_name}
                            onChange={e => updateField("given_name", e.target.value)}
                            label="First Name"
                            errortext=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input 
                            value={userDetail.family_name}
                            onChange={e => updateField("family_name", e.target.value)}
                            label="Last Name"
                            errortext=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input 
                            value={userDetail.UserStatus}
                            disabled={true}
                            label="Status"
                            errortext=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Input 
                            value={userDetail.email}
                            onChange={e => updateField("email", e.target.value)}
                            label="Email"
                            errortext=""
                            helperText="When changed email verified will be set to false, an email will be sent to the users email address"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Select 
                            value={userDetail.email_verified}
                            onChange={e => updateField("email_verified", e.target.value)}
                            label="Email Verified"
                            errortext="">
                            <MenuItem value={"true"}> True </MenuItem>
                            <MenuItem value={"false"}> False </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Input 
                            value={userDetail.phone_number}
                            onChange={e => updateField("phone_number", e.target.value)}
                            label="Phone"
                            errortext=""
                            helperText="When changed phone # verified will be set to false"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Select 
                            value={userDetail.phone_number_verified}
                            onChange={e => updateField("phone_number_verified", e.target.value)}
                            label="Phone Verified"
                            errortext="">
                            <MenuItem value={"true"}> True </MenuItem>
                            <MenuItem value={"false"}> False </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input 
                            value={userDetail.groups.join(", ")}
                            disabled={true}
                            label="Groups"
                            errortext=""
                            helperText=""
                        />
                    </Grid>
                </Grid>

                <Button
                    className={classes.submitButton}
                    text={"Update"}
                    color='primary'
                    size='medium'
                    onClick={handleSubmit}
                />
            </Paper>
        </Page>
    )
}


const mapStateToProps = ({ userDetail }) => ({ userDetail })
const actionCreators = { getUserDetail, toggleEnabled, updateAttribute, setModal, updateField, updateAttributes, setSnackbar };

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(UserDetails));