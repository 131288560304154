function FormValidator(refs, updateField) {
    let invalid = []
    Object.keys(refs).forEach(key => {
        if(refs[key].props && refs[key].props.validator) {
            let valid = refs[key].props.validator()
            if(updateField) updateField(key, refs[key].props.value, valid);
            if(!valid) invalid.push({
                field: key,
                message: refs[key].props.errorMessage,
                ref: refs[key]
            });
        }
    })

    return invalid;
}

export default FormValidator;