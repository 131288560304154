import awsConfigDev, { UserPoolDev } from './aws-config.dev';
import awsConfigStage, { UserPoolStaging } from './aws-config.staging';
import awsConfigProd, { UserPoolProd } from './aws-config.prod';

let awsConfig;
let UserPool;
// load the correct aws config options
switch(process.env.REACT_APP_ENV) {
  case 'prod':
    awsConfig = awsConfigProd;
    UserPool = UserPoolProd;
  break
  case 'staging':
    awsConfig = awsConfigStage;
    UserPool = UserPoolStaging
  break
  case 'dev':
    awsConfig = awsConfigDev
    UserPool = UserPoolDev
  break
  default:
    awsConfig = awsConfigDev
    UserPool = UserPoolDev
}
export {
    awsConfig,
    UserPool
}