export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR';
export const TOGGLE_USER_ENABLED_SUCCESS = 'TOGGLE_USER_ENABLED_SUCCESS';
export const TOGGLE_USER_ENABLED_ERROR = 'TOGGLE_USER_ENABLED_ERROR';
export const USER_UPDATE_ATTRIBUTE_SUCCESS = 'USER_UPDATE_ATTRIBUTE_SUCCESS';
export const USER_UPDATE_ATTRIBUTE_ERROR = 'USER_UPDATE_ATTRIBUTE_ERROR';
export const USER_UPDATE_ATTRIBUTES_SUCCESS = 'USER_UPDATE_ATTRIBUTES_SUCCESS';
export const USER_UPDATE_ATTRIBUTES_ERROR = 'USER_UPDATE_ATTRIBUTES_ERROR';
export const USER_DETAIL_UPDATE_FIELD = 'USER_DETAIL_UPDATE_FIELD'
export const RESET_USER_DETAIL = 'RESET_USER_DETAIL'

export const INIT = {
    groups: [],
    email: '',
    email_verified: '',
    phone_number: '',
    phone_number_verified: '',
    given_name: '',
    UserStatus: '',
    family_name: ''
};

export default function(state = INIT, { type, payload }) {

  switch (type) {
    case GET_USER_DETAIL_SUCCESS:
    case TOGGLE_USER_ENABLED_SUCCESS:
    case USER_UPDATE_ATTRIBUTE_SUCCESS:
    case USER_DETAIL_UPDATE_FIELD:
        return payload;
    case RESET_USER_DETAIL:
        return INIT;
    case GET_USER_DETAIL_ERROR:
    case TOGGLE_USER_ENABLED_ERROR:
    case USER_UPDATE_ATTRIBUTE_ERROR:
    case USER_UPDATE_ATTRIBUTES_SUCCESS:
    case USER_UPDATE_ATTRIBUTES_ERROR:
    default:
        return state;
  }
}
