import AWSConfig from '../../aws/AWSConfig'
import cloneDeep from 'lodash.clonedeep'

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const RESET_USERS = 'RESET_USERS'

export const get = (options = {}) => async (dispatch, getState) => {
  const users = cloneDeep(options.users) || [];
  
  try {

    let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

    var params = {
      UserPoolId: AWSConfig.UserPool,
      AttributesToGet: [
        "email"
      ],
      Limit: 50,
      Filter: options.Filter ? options.Filter : null,
      PaginationToken: options.PaginationToken ? options.PaginationToken : null
    }

    let data = await CognitoIdentityServiceProvider.listUsers(params).promise()

    // Set the attributes on the main user object
    // The attributes come in as an object = { Name: String, value: String }, which could be annoying to traverse
    let mappedUsers = data.Users.map(user => {
      user.Attributes.forEach(attribute => {
        user[attribute.Name] = attribute.Value
      })
      delete user.Attributes
      return user;
    })

    for(var i = users.length - 1; i >= 0 ; i--){
      let user = users[i];
      mappedUsers.unshift(user);
    }

    let payload = {
      rows: mappedUsers,
      PaginationToken: data.PaginationToken
    }
    
    dispatch({
      type: GET_USERS_SUCCESS,
      payload
    })

    return payload;
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: GET_USERS_ERROR
    })

    throw error;
  }
}

export const reset = () => {
  return {
    type: RESET_USERS,
    payload: {}
  };
}