import cloneDeep from 'lodash.clonedeep'
import { Auth } from 'aws-amplify'
import { setCognitoUser } from './cognitoUser'
import { 
  INIT,
  SET_USER,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESS,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_ERROR,
} from '../reducers/user';;

export const getCurrentUser = () => async (dispatch) => {
  try {
    await Auth.currentAuthenticatedUser();
    const response = await Auth.currentSession();
    const user = response.idToken.payload;


    // Make API Request to get current user data
    // let apiResponse = await API.get("FlaggeradeAPI", `/api/v1/user/${user.sub}`)
    // let { id, UserId, createdAt, updatedAt, ...address} = apiResponse.user.Address
    // apiResponse.user = {...address, ...apiResponse.user};

    let payload = cloneDeep(INIT);

    Object.keys(user).forEach(key => {
      if(payload[key])
        payload[key].value = user[key] ? user[key] : ""
      else 
        payload[key] = user[key]
    })

    // Check user type
    // if (!['Admin', 'Dispatch'].includes(payload.type)) {
    //   throw new Error("Invalid User Type, you must be a Driver to log into this application");
    // }
    // if(user.status !== "Activated") {
    //   throw new Error(`Your account is in ${user.status} status. Please contact us for more information`);
    // }

    dispatch({
      type: GET_CURRENT_USER_SUCCESS,
      payload: payload
    })

    return payload
  }
  catch(error) {

    await dispatch(signOut());
    dispatch({
      type: GET_CURRENT_USER_ERROR,
    });

    throw error;
  }
}

export const signOut = () => async (dispatch) => {
  try {
    await Auth.signOut();
    await dispatch(setCognitoUser({}));
    dispatch({
      type: USER_SIGN_OUT_SUCCESS,
    });

    return true;
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_SIGN_OUT_ERROR,
    });

    return false;
  }
};

export const setUser = user => {
  if (user) {
    return {
      type: SET_USER,
      payload: user
    }
  }
  // Empty user in store if no user is sent
  return {
    type: SET_USER,
    payload: INIT
  }
}
