import React, { useState } from 'react';
import { Auth }             from 'aws-amplify';
import validator from 'validator'

import FormValidator  from '../../../modules/FormValidator';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import useStyles from './styles';

const ForgotPassword = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState({value: '', valid: true});
  const [loading, setLoading] = useState(false);

  let refs = {
    email: React.createRef()
  }

  async function handleForgotPassword(e) {
    e.preventDefault();

    const invalidFields = FormValidator(refs);

    if(invalidFields.length > 0) {
      return handleError(new Error(invalidFields[0].message))
    }

    setLoading(true);

    try {
      await Auth.forgotPassword(email.value.toLowerCase())
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      props.history.push(`/reset-password?email=${email.value}`);
    } catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }



  return(
    <AuthForm className={classes.root} onSubmit={handleForgotPassword}>

      <AuthHeader />
        
        <Input
          id='email'
          ref={(el) => refs.email = el}
          errorMessage={"A valid email is required to reset your password"}
          validator={() => validator.isEmail(email.value)}
          marginBottom={24}
          label='Email Address'
          onChange={e => setEmail({value: e.target.value, valid: true})}
          value={email.value}
          error={!email.valid} />

        <input type="submit" style={{ display: 'none' }} />

        <Button
          color="primary"
          disabled={loading}
          fullWidth={true}
          onClick={handleForgotPassword}
          loading={loading}
          text={'Request Password Reset'}
          size="large"
          variant="contained"
        />

    </AuthForm>
  );
}

export default ForgotPassword;