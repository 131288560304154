const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 24
        },
        [theme.breakpoints.up('md')]: {
            padding: 48
        }
    }
});

export default styles;