import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress'
import Button           from '@material-ui/core/Button';
import Table            from '@material-ui/core/Table';
import TableBody        from '@material-ui/core/TableBody';
import TableCell        from '@material-ui/core/TableCell';
import TableHead        from '@material-ui/core/TableHead';
import TableRow         from '@material-ui/core/TableRow';
import TableSortLabel   from '@material-ui/core/TableSortLabel';
// import TablePagination  from '@material-ui/core/TablePagination';
import ToolTip          from '@material-ui/core/Tooltip';

import styles from './styles';


const CustomTable = props => {
    let { classes, headers, rows, onSort, loading, onLoadMore } = props;


    const [order, setOrder] = useState(props.startOrder || 'desc');
    const [orderBy, setOrderBy] = useState(props.startOrderIndex || 0); // 2 is the index for the column 'date'

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if(typeof onSort === 'function'){
            onSort(newOrder, index);
            return;
        }
    };

    
    if(loading){
        return (
            <div className={classes.progress}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    
    return (
        <React.Fragment>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header, index) => (
                                    props.sorted ? 
                                    <TableCell
                                        key={`header-${index}`}>
                                        <ToolTip
                                            title="Sort"
                                            enterDelay={300}>
                                            <TableSortLabel
                                                active={orderBy === index}
                                                direction={order}
                                                onClick={e => handleRequestSort(e, index)}
                                                className={classes.tableSortLabel}>
                                                {header}
                                            </TableSortLabel>
                                        </ToolTip>
                                    </TableCell> 
                                    :
                                    <TableCell key={`header-${index}`}>
                                        {header}
                                    </TableCell>

                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map(({ cols }, index) => (
                                <TableRow key={`row-${index}`}>
                                    {
                                        cols.map((cell, cIndex) => {

                                            return (
                                                <TableCell key={`cell-${index}-${cIndex}`}>
                                                    <div>
                                                        {cell}
                                                    </div>
                                                </TableCell>
                                            );
                                        }) 
                                    }
            
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
            {
                props.PaginationToken ?
                <div className={classes.pagination}>
                    <Button onClick={onLoadMore}>
                        Load More
                    </Button>
                </div> : ''
            }
        </React.Fragment>
    );
};

export default withStyles(styles)(CustomTable);