import React, { useState, useEffect }   from 'react';
import { Auth }               from 'aws-amplify';
import { connect }            from 'react-redux';
import {
  Typography
} from '@material-ui/core';

import { getCurrentUser } from '../../../redux/actions/user';
import { setCognitoUser } from '../../../redux/actions/cognitoUser';
import FormValidator  from '../../../modules/FormValidator';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import useStyles from './styles';

const NewPassword = (props) => {
  const { challengeUser, getCurrentUser, setCognitoUser } = props;
  const classes = useStyles();

  const [password, setPassword] = useState({value: '', valid: true})
  const [passwordMatch, setPasswordMatch] = useState({value: '', valid: true})
  const [loading, setLoading] = useState(false);

  let refs = {
    password: React.createRef(),
    passwordMatch: React.createRef()
  }

  useEffect(() => {
    if(!challengeUser) props.history.replace('/');
  }, [challengeUser, props.history])

  async function handleNewPassword(e) {
    e.preventDefault();

    const invalidFields = FormValidator(refs);

    if (invalidFields.length > 0) {
      return handleError(invalidFields[0].message)
    }

    setLoading(true)
    try {
      await Auth.cleanCachedItems();
      await Auth.completeNewPassword(challengeUser, password.value);
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      await getCurrentUser();
      let userInfo = await Auth.currentUserInfo()
      setCognitoUser(userInfo);
    }
    catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm className={classes.root} onSubmit={handleNewPassword}>

      <AuthHeader />

      <div className={classes.helpText}>
        <Typography variant="body2" component={"p"}>
          Please set your new password before continuing
        </Typography>
      </div>

      <Input
        id='password'
        ref={el => refs.password = el}
        errorMessage="Password must be greater than 8 characters"
        validator={() => password.value.length > 8}
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setPassword({value: e.target.value, valid: true})}
        value={password.value}
        error={!password.valid} />
      <Input
        id='password-match'
        ref={el => refs.passwordMatch = el}
        errorMessage="Confirm password and Password do not match"
        validator={() => password.value === passwordMatch.value}
        marginBottom={24}
        label='Confirm Password'
        type='password'
        onChange={e => setPasswordMatch({value: e.target.value, valid: true})}
        value={passwordMatch.value}
        error={!passwordMatch.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        loading={loading}
        fullWidth={true}
        onClick={handleNewPassword}
        size="large"
        text={'Set Password'}
        variant="contained"
      />

    </AuthForm>
  );
}

// const mapStateToProps = ({  }) => ({  });
const actions = { getCurrentUser, setCognitoUser }

export default connect(null, actions)(NewPassword);