export const RESET_USER_FORM = "RESET_USER_FORM"
export const USER_FORM_UPDATE_FIELD = "USER_FORM_UPDATE_FIELD"
export const USER_FORM_SUBMIT_SUCCESS = "USER_FORM_SUBMIT_SUCCESS"
export const USER_FORM_SUBMIT_ERROR = "USER_FORM_SUBMIT_ERROR"
export const INIT = {
    email: {
        value: '',
        valid: true
    },
    given_name: {
        value: '',
        valid: true
    },
    family_name: {
        value: '',
        valid: true
    },
    phone: {
        value: '',
        valid: true
    }
};

export default function(state = INIT, { type, payload }) {

  switch (type) {
    case USER_FORM_UPDATE_FIELD:
    case USER_FORM_SUBMIT_SUCCESS:
        return payload;
    case RESET_USER_FORM:
        return INIT;
    case USER_FORM_SUBMIT_ERROR:
    default:
        return state;
  }
}
