import React, { useState } from 'react';
import { connect }         from 'react-redux';
import { withRouter }      from 'react-router'

// Material UI Core
import AppBar         from '@material-ui/core/AppBar';
import Drawer         from '@material-ui/core/Drawer';
import Toolbar        from '@material-ui/core/Toolbar';
import List           from '@material-ui/core/List';
import ListItem       from '@material-ui/core/ListItem';
import ListItemIcon   from '@material-ui/core/ListItemIcon';
import ListItemText   from '@material-ui/core/ListItemText';
import Typography     from '@material-ui/core/Typography';
import IconButton     from '@material-ui/core/IconButton';
import Hidden         from '@material-ui/core/Hidden';

// Material Icons
import PersonIcon        from '@material-ui/icons/Person';
import ExitToAppIcon     from '@material-ui/icons/ExitToApp';
import HomeIcon          from '@material-ui/icons/Home';
import MenuIcon          from '@material-ui/icons/Menu';

// Redux
import { signOut } from '../../redux/actions/user';

import CompanyLogo from '../../assets/logo.jpg';

import useStyles from './styles';
import { useTheme } from '@material-ui/styles';

const Navigation = (props) => {
  const { signOut } = props;

  const theme = useTheme();
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  async function handleLogout() {
    await signOut()
  }
  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={CompanyLogo} alt="Company Logo" className={classes.logo} />
      </div>
      <List>
        <ListItem button onClick={e => props.history.push('/dashboard')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={e => props.history.push('/dashboard/enroll')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Enroll Doctor" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={e => setMobileOpen( !mobileOpen )}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={e => setMobileOpen( !mobileOpen )}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        { props.children }
      </main>
      
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: payload => {
      dispatch(signOut(payload));
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Navigation));
