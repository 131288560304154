
export default {
  palette: {
    primary: {
      main: '#31365e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#626689',
      contrastText: '#ffffff'
    },
    error: {
      main: '#e50000',
      contrastText: '#ffffff'
    }
  },
  overrides: {
  }
};
