import { GET_USERS_SUCCESS, GET_USERS_ERROR, RESET_USERS } from '../actions/users';

export const INIT = {
  count: 0,
  PaginationToken: '',
  rows: []
};

export default function(state = INIT, { type, payload }) {

  switch (type) {
    case GET_USERS_SUCCESS:
      return payload;
    case GET_USERS_ERROR:
      return state;
    case RESET_USERS:
      return INIT;
    default:
      return state;
  }
}
