export const SET_MODAL = 'SET_MODAL';

export function setModal(payload) {
    payload.open = true; // automatically set this

    return {
        type: SET_MODAL,
        payload
    }
}

export function closeModal() {
    return {
        type: SET_MODAL,
        payload: { open: false }
    }
}