const styles = theme => ({
    tableSortLabel: {
        fontSize: 16,
        fontWeight: 600
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center'
    },
    paginationRoot: {
        borderBottomWidth: 0
    },
    progress: {
        display: 'flex',
        justifyContent: 'center'
    },
    tableWrapper: {
        overflowX: "scroll"
    }
});

export default styles;