export const SET_COGNITO_USER = 'SET_COGNITO_USER';
export const INIT = {};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_COGNITO_USER:
      // case for clearing user data
      if(JSON.stringify(payload) === JSON.stringify({})) {
        return payload;
      }
      // otherwise merge new user changes to state
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
