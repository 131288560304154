import { 
    INIT,
    SET_COGNITO_USER,
} from '../reducers/cognitoUser';;

export const setCognitoUser = user => {
    if (user) {
        return {
            type: SET_COGNITO_USER,
            payload: user
        }
    }
    // Empty user in store if no user is sent
    return {
        type: SET_COGNITO_USER,
        payload: INIT
    }
}
  