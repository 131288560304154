import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect }                 from 'react-redux'
import { setSnackbar }             from '../../redux/actions/snackbar'
import { withStyles }              from '@material-ui/core/styles';
import checkError                  from '../../modules/check-error';


import Navigation   from '../../components/Navigation'

import Dashboard from './Dashboard'
import UserDetails from './UserDetails'
import Enroll from './Enroll'

import styles from './styles';

const AdminStack = props => {
    const { setSnackbar } = props;

    function handleError(error) {
        setSnackbar(checkError(error));
    }

    /** Initial route */
    const _Dashboard = (props) => <Dashboard onError={handleError} {...props} />;

    /** Route used after registering, or if logging in and account is unregistered */
    const _UserDetails = (props) => <UserDetails onError={handleError} {...props} />;
    const _Enroll = (props) => <Enroll onError={handleError} {...props} />;

    return (
        <Navigation>
            <Switch>
                <Route exact path="/dashboard" component={_Dashboard} />
                <Route exact path="/dashboard/user/:id" component={_UserDetails} />
                <Route exact path='/dashboard/enroll' component={_Enroll} />
                <Route exact path="/dashboard/*" component={() => <Redirect to='/dashboard' />} />
                <Route exact path="/" component={() => <Redirect to='/dashboard' />} />
            </Switch>
        </Navigation>
    );
}

const actions = { setSnackbar }
export default connect(null, actions)(withStyles(styles)(AdminStack));