import cloneDeep from 'lodash.clonedeep'
import phone from 'phone'
import AWSConfig from '../../aws/AWSConfig'
import { INIT as USER_FORM_INIT } from '../reducers/userForm'

import { 
  USER_FORM_UPDATE_FIELD,
  RESET_USER_FORM,
  USER_FORM_SUBMIT_ERROR,
  USER_FORM_SUBMIT_SUCCESS
} from '../reducers/userForm';

export const submit = () => async (dispatch, getState) => {
  const { userForm } = cloneDeep(getState());

  let CognitoIdentityServiceProvider = await AWSConfig.GetCognitoIdentityServiceProvider() 

  try {
    var params = {
      DesiredDeliveryMediums: ["EMAIL", "SMS"],
      UserPoolId: AWSConfig.UserPool,
      Username: userForm.email.value.toLowerCase(),
      UserAttributes: [
        {
          Name: "email",
          Value: userForm.email.value.toLowerCase()
        },
        {
          Name: "given_name",
          Value: userForm.given_name.value
        },
        {
          Name: "family_name",
          Value: userForm.family_name.value
        },
        {
          Name: "phone_number",
          Value: phone(userForm.phone.value)[0]
        },
        {
          Name: "email_verified", Value: 'true'
        },
        {
          Name: "phone_number_verified", Value: 'true'
        }
      ],
      ValidationData: [
        {
          Name: "firstName",
          Value: userForm.given_name.value
        },
        {
          Name: "lastName",
          Value: userForm.family_name.value
        },
        {
          Name: "phone",
          Value: phone(userForm.phone.value)[0]
        }
      ],
    }

    let response = await CognitoIdentityServiceProvider.adminCreateUser(params).promise();

    dispatch({
      type: USER_FORM_SUBMIT_SUCCESS,
      payload: USER_FORM_INIT
    })

    return response;
  }
  catch(error) {
    console.log(error);
    dispatch({
      type: USER_FORM_SUBMIT_ERROR
    })
    throw error;
  }
}

export const updateField = (Field, Value, Valid=true) => (dispatch, getState) => {
  const { userForm } = cloneDeep(getState());

  userForm[Field].value = Value;
  userForm[Field].valid = Valid;

  dispatch({
    type: USER_FORM_UPDATE_FIELD,
    payload: userForm
  })

  return userForm;
  
}

export const reset = () => {
  return {
    type: RESET_USER_FORM
  };
}