import React, { useState } from 'react';
import { connect }          from 'react-redux';
import { Auth }             from 'aws-amplify';

import validator from 'validator'
import FormValidator  from '../../../modules/FormValidator';
import { setSnackbar } from '../../../redux/actions/snackbar';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import useStyles from './styles';

const Register = (props) => {
  const { setSnackbar } = props;
  const classes = useStyles();

  const [email, setEmail] = useState({value: '', valid: true});
  const [password, setPassword] = useState({value: '', valid: true});
  const [passwordMatch, setPasswordMatch] = useState({value: '', valid: true});
  const [loading, setLoading] = useState(false);

  let refs = {
    email: React.createRef(),
    password: React.createRef(),
    passwordMatch: React.createRef(),
  }

  async function handleRegister(e) {
    e.preventDefault();

    const invalidFields = FormValidator(refs);

    if (invalidFields.length > 0) {
      return handleError(invalidFields[0].message);
    }

    setLoading(true);
    try {
      const signupParams = {
        username: email.value.toLowerCase(),
        password: password.value,
        attributes: {
          email: email.value.toLowerCase()
        }
      };

      await Auth.signUp(signupParams);
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      setSnackbar('User account was created successfully! Please check your email to verify your account.');
      props.history.push(`/confirm?email=${email.value}`);
      props.history.push('/login');
    }
    catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }


  return(
    <AuthForm className={classes.root} onSubmit={handleRegister}>
      
      <AuthHeader />

      <Input
        id='email'
        ref={el => refs.email = el}
        errorMessage="Must provide a valid email"
        validator={() => validator.isEmail(email.value)}
        marginBottom={16}
        label='Email Address'
        onChange={e => setEmail({value: e.target.value, valid: true})}
        value={email.value}
        error={!email.valid} />
      <Input
        id='password'
        ref={el => refs.password = el}
        errorMessage="Password must be greater than 8 characters"
        validator={() => password.value.length > 8}
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setPassword({value: e.target.value, valid: true})}
        value={password.value}
        error={!password.valid} />
      <Input
        id='passwordMatch'
        ref={el => refs.passwordMatch = el}
        errorMessage="Confirm password and Password do not match"
        validator={() => password.value === passwordMatch.value}
        marginBottom={24}
        label='Confirm Password'
        type='password'
        onChange={e => setPasswordMatch({value: e.target.value, valid: true})}
        value={passwordMatch.value}
        error={!passwordMatch.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        disabled={loading}
        loading={loading}
        fullWidth={true}
        onClick={handleRegister}
        size="large"
        text={'Sign Up'}
        variant="contained"
      />

    </AuthForm>
  );
}

export default connect(null, { setSnackbar })(Register);