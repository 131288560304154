import React, { useState, useEffect } from 'react';
import { Auth }             from 'aws-amplify';
import queryString          from 'query-string';

import FormValidator  from '../../../modules/FormValidator';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/Button';
import Input      from '../../../components/Input';

import useStyles from './styles';

const ResetPassword = (props) => {
  const classes = useStyles();
  
  const [email] = useState(queryString.parse(props.location.search).email)
  const [code, setCode] = useState({value: '', valid: true})
  const [password, setPassword] = useState({value: '', valid: true})
  const [passwordMatch, setPasswordMatch] = useState({value: '', valid: true})
  const [loading, setLoading] = useState(false);

  let refs = {
    code: React.createRef(),
    password: React.createRef(),
    passwordMatch: React.createRef(),
  }

  useEffect(() => {
    if(!email) props.history.replace('/forgot-password');
  }, [email, props.history])

  async function handleResetPassword(e) {
    e.preventDefault();

    const invalidFields = FormValidator(refs);

    if (invalidFields.length > 0) {
      return handleError(invalidFields[0].message);
    }

    setLoading(true)
    try {
      await Auth.forgotPasswordSubmit(email, code.value, password.value)
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      props.history.replace('/')
    }
    catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm className={classes.root} onSubmit={handleResetPassword}>

      <AuthHeader />

      <Input
        id='email'
        disabled={true}
        marginBottom={16}
        label='Email Address'
        value={email} />
      <Input
        id='code'
        ref={el => refs.code = el}
        errorMessage="Code is requied"
        validator={() => code.value.length > 0}
        marginBottom={16}
        label='Code'
        onChange={e => setCode({value: e.target.value, valid: true})}
        value={code.value}
        error={!code.valid} />
      <Input
        id='password'
        ref={el => refs.password = el}
        errorMessage="Password must be greater than 8 characters"
        validator={() => password.value.length > 8}
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setPassword({value: e.target.value, valid: true})}
        value={password.value}
        error={!password.valid} />
      <Input
        id='passwordMatch'
        ref={el => refs.passwordMatch = el}
        errorMessage="Confirm password and Password do not match"
        validator={() => password.value === passwordMatch.value}
        marginBottom={16}
        label='Confirm Password'
        type='password'
        onChange={e => setPasswordMatch({value: e.target.value, valid: true})}
        value={passwordMatch.value}
        error={!passwordMatch.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        loading={loading}
        fullWidth={true}
        onClick={handleResetPassword}
        size="large"
        text={'Reset Password'}
        variant="contained"
      />
      
    </AuthForm>
  );
}

export default ResetPassword;