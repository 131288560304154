import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {

  },

  helpText: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  }
});

export default makeStyles(styles);