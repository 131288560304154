import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from '../styles';

class Select extends React.Component {
    render() {
        const { classes, error, errorMessage, validator, children, marginBottom, InputLabelProps, ...rest } = this.props;
        

        return (
            // set defaults for text fields app-wide
            <TextField
                select
                type='text'
                fullWidth={true}
                style={marginBottom ? {marginBottom} : {}}
                error={!!error}
                InputLabelProps={{
                    className: classes.label,
                    ...InputLabelProps
                }}
                {...rest}
            >

                { children }
                
            </TextField>
        );
    }
}

export default withStyles(styles)(Select);
