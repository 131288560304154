import { SET_MODAL } from '../actions/modal';

export const INIT = {
  open: false,
  title: '',
  message: '',
  buttons: []
};

export default function(state = INIT, { type, payload }) {
  let newState = Object.assign({}, state, payload);

  switch(type) {
    case SET_MODAL:
      return newState;
    default:
      return state;
  }
}