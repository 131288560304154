export const SET_USER = 'SET_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';
export const USER_SIGN_OUT_SUCCESS = "USER_SIGN_OUT_SUCCESS"
export const USER_SIGN_OUT_ERROR = "USER_SIGN_OUT_ERROR"
export const INIT = {};

export default function(state = INIT, { type, payload }) {

  switch(type) {

    case SET_USER:
      // case for clearing user data
      if(JSON.stringify(payload) === JSON.stringify({})) {
        return payload;
      }
      // otherwise merge new user changes to state
      return Object.assign({}, state, payload);
    case GET_CURRENT_USER_SUCCESS:
      return payload;
    case USER_SIGN_OUT_SUCCESS:
      return {}
    default:
      return state;
  }
}
